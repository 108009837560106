﻿html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: $font-family-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    color: $body-color;
    margin-bottom: 0;

    &.bodyclass {
        overflow: hidden !important;

        .togglesearch {
            z-index: 1000;
        }
    }
}

    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1280px !important;

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
            overflow-wrap: anywhere;
        }
    }

    ::-moz-selection {
    }

    ::selection {
    }

hr {
    border-top: 2px solid #b9b9b9;
}

/*.breadcrumbs ul {
    display: inline-flex;
}*/
.breadcrumb {
    background-color: none !important;
    
}
.breadcrumbs ol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .75rem 1rem .75rem 0;
    margin-bottom: 1rem;
    list-style: none;
    background-color: none;
    border-radius: .25rem;
    

    li {
        padding-right: 7px;
        list-style-type: none;


        a {
            color: $highlight-color;
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

.breadcrumbs-container {
    margin-top: 10px;
    z-index: 1000;
    position:relative;
}

.breadcrumbs {
    ol {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: .75rem 1rem .75rem 0;
        margin-bottom: 1rem;
        list-style: none;
        background-color: none;
        border-radius: .25rem;

        li {
            padding-right: 7px;
            list-style-type: none;

            @include media-breakpoint-down(sm) {
                font-size: .8rem;
            }

            a {
                color: $highlight-color;
                text-decoration: none;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}

.breadcrumbs-container {
    margin-top: 10px;
}

.notes {
    ul {
        padding-left: 1.5rem;

        li {
            list-style: square;

            ul {

                li {
                    list-style: circle;
                }
            }
        }
    }
}

.full-gray-fill {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: $light-gray;
    margin-bottom: $standard-block-bottom-margin;

    &.bottom-fill {
        margin-bottom: 0px;
    }
}

.home-page {
    .products-listing {
        margin-bottom: $standard-block-bottom-margin;
    }

    .full-gray-fill {
        padding-top: 2rem;
        padding-bottom: 2rem;
        background-color: $light-gray;
       
        &.bottom-fill {
            margin-bottom:0px;
        }
    }
}

a img {
    text-decoration: none;
    border: 0 none;
}

    /*.table-striped tbody tr:nth-of-type(odd) {
        background-color: $very-light-gray;
    }*/

    .newsletter-wrapper {
        background-color: $header-gray;
        min-height: 203px;
        color: $white;
    }

    .newsletter-wrapper h1 {
        color: $highlight-color;
        font-family: $font-family-condensed;
        font-weight: 800;
        font-size: 3.5rem;
        margin-bottom: 0px;
    }

    .newsletter-wrapper span {
        font-size: 1.6rem;
    }

    .newsletter-wrapper input {
        height: 63px;
        border-radius: 0;
    }

    .newsletter-wrapper button {
        height: 63px;
        border-radius: 0;
        margin-top: 8px;
    }

    .newsletter-wrapper img {
        height: 54px;
        width: 54px;
    }

    .newsletter-wrapper h1 {
        font-size: 3rem;
        margin-bottom: 0px;
        margin-top: 20px;
    }

    .newsletter-wrapper span {
        font-size: 1.2rem;
    }


    .footer {
        position: static;
        /*bottom: 0;*/
        width: 100%;
        /* fixed height */
        min-height: 300px;
        color: $light-gray;
        font-size: 21px;
        font-family: $font-family-body;

        @include media-breakpoint-down(md) {
            min-height: 415px;
        }

        a {
            color: $light-gray;
            text-decoration: none;
            transition: all 0.3s ease-in-out;
        }

        a:hover {
            color: $white;
            transition: all 0.3s ease-in-out;
            text-decoration: underline;
        }

        span {
            display: block;
        }

        ul {
            list-style: none;
            padding-left: 0px;
        }

        .footer-menu-column {
            ul {
                li:first-child {
                    a {
                        font-family: $font-family-condensed;
                        font-weight: 600;
                        font-size: 1.15rem;
                    }
                }
            }
        }
    }

    .recall {
        text-align: right;

        @include media-breakpoint-down(md) {
            text-align: left;
        }
    }

    .social-media-icon {
        display: inline-block;
        margin: 0 10px 0 0;

        svg {
            fill: $white;
        }
    }

    .social-media-icon a {
        display: inline-block;
    }

    .social-media-icon:hover svg {
        fill: $highlight-color;
    }

    .social-media-icon svg {
        width: 25px;
        height: 25px;
    }



.ffs-green {
    color: $button-font-color;
    background-position: right;
    background-repeat: no-repeat;
    background: rgb(62,126,142);
    background: linear-gradient(135deg, $button-color 0%, $button-color 35%, $button-hover-color 35%);
    background-size: 200% 100%;
    box-shadow: inset 0 0 0 0 #D80286;
    transition: all 0.3s ease;

    &:hover {
        background-position: right bottom;
        color: $button-font-color;
        transition: all 0.3s ease;
    }
}

.fe-btn {
    border: 1px solid #99bbc3;
    border-radius: 0;
    display: inline-block;
    padding: 8px 6px 8px 17px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none !important;
    text-transform:capitalize;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    font-family: $font-family-compressed;
    font-weight: 400;
    font-size: 1.1rem;

    @include media-breakpoint-down(sm) {
        white-space: normal;
    }
    .btn-text {
        padding-right: 62px;
            
        @include media-breakpoint-down(sm) {
            padding-right: 32px;
        }
    }
    svg {
        fill: $white;
        width:20px;
    }
}

.ffs-btn {
    border: none;
    border-radius: 0;
    transition: background-color 0.3s ease-out;
    font-family: $font-family-compressed;
    font-weight: 700;
    font-size: 1.4rem;
    text-transform: uppercase;

    @include media-breakpoint-down(sm) {
        margin-top: 16px;
    }
}

    .ffs-btn-lg {
        padding: .45rem 1rem;
    }

    .ffs-btn:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }

    .ffs-green-hr {
        border-top: 3px solid $highlight-color;
    }

    .water-systems-btn {

    }

    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;

        iframe {
            vborder: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    .form-control:focus {
        color: #495057;
        border-color: $highlight-color;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .input-group-lg > .input-group-append > .btn {
        border-radius: 0;
    }

    .search-form {
        .text-input {
            border-radius: 0;
        }

        .btn {
            border-radius: 0;
            background-color: $highlight-color;
            font-family: $font-family-compressed;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

#cookie-consent {
    border-radius: 0;
    border-color: transparent;
    background-color: #575757;
    color: #fff;
    margin-bottom: 0.4rem;

    @include media-breakpoint-down(md) {
        font-size: .8rem;
        margin-top: 60px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 45px;
    }

    a {
        color: #fff;
        font-weight: bold;

        &:hover {
            text-decoration: none;
        }
    }

    #cookie-consent-close {
        line-height: .8;
    }

    .close {

        &:hover {
            color: #8a8a8a;
        }
    }
}


    .service-banner {
        background-color:#c3c3c3;
        border-color: $highlight-color-light;
        border-radius: 0;
        border:none;

        @include media-breakpoint-down(md) {
            font-size: .8rem;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .ValidationMessage {
        color: red;
    }

    .black-text {
        color: #222;
    }

    .white-text {
        color: #fff;
    }


.water-systems {
    .row-bottom-border {
        border-top: 1px solid #bbb;
        padding-top: 1.5rem;
        padding-bottom: .8rem;

        @include media-breakpoint-down(md) {
            padding-top: 1rem;
            padding-bottom: .4rem;
        }
    }

    .cta-link {
        font-weight: 500;
    }

    .news-block {
        margin-bottom: $standard-block-bottom-margin;

        .media:first-child {
            padding-top: 1.5rem;
        }

        .product-icon {
            img {
                width: 180px;

                @include media-breakpoint-down(md) {
                    width: 120px;
                }
            }
        }

        h2 {
            display: inline-block;
            padding-right: 3rem;
        }

        .all-news-link {
            a {
                color: $highlight-color;
            }
        }

        a {
            text-decoration: none;

            h3 {
                font-weight: 500;

                @include media-breakpoint-down(md) {
                    font-size: 1.2rem;
                }
            }

            span {
                font-weight: 400;
            }

            .media-date {
                font-size: unset;
            }
        }
    }

    .events-block {
        .all-events-link {
            a {
                color: $highlight-color;
            }
        }
     }
}
.water-systems-search-page {
    .tab-content hr {
    border-top: 1px solid #b9b9b9;
    }
}

.raw-html-block.mt-5 {
    margin-top: 0px !important;
}

.technicalSupportPage {

    .inline-faq {

        .featuredFAQInner > div {
            box-shadow: 0px 0px 15px #c9c9c9;
            border-radius: 30px;
            padding: 1.5em;
            width: 32%;
            margin-left: 2%;
            display: inline-block;
            vertical-align: top;
        }

        @include media-breakpoint-down(md) {
            .featuredFAQInner > div {
                width: 49%;
                margin-left: 2%;
            }

            .featuredFAQInner > div:last-child {
                margin-left: 0;
            }
        }

        .featuredFAQInner > div:first-child {
            margin-left: 0;
        }

        @include media-breakpoint-down(sm) {
            .featuredFAQInner > div {
                width: 100%;
                margin-left: 0;
                margin-bottom:1em;
            }

            .featuredFAQInner > div:last-child {
                margin-left: 0;
            }
        }
    }

    .container {
        margin-top: 1em;
    }
}
.allFAQLinks {

    margin:1em auto 2em auto;
    //margin-bottom:2em;

    ul {
        padding: 0;
        overflow: hidden;
    }

    li {
        width: 50%;
        float: left;
        list-style: none;
    }

    li:nth-child(odd) {
        clear: left;
    }

    a {
        padding: .5em 0;
        color: #000000;
        display: block;
    }

    Li:hover {
        background-position: right bottom;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    @include media-breakpoint-down(sm) {
        li {
            width: 100%;
            float: none;
        }
    }
}

    .inline-faq {
        //Padding-bottom: 1em;
    }


