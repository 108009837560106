﻿.hero-banner {
    background-repeat: no-repeat;

    @include media-breakpoint-down(sm) {
        min-height: 220px;
        background-position: top;
        background-size: cover;
    }

    @include media-breakpoint-down(md) {
        min-height: 250px;
        background-position: top;
        background-size: cover;
    }

    @include media-breakpoint-up(lg) {
        min-height: 330px;
        background-position: top;
        position: relative;
    }
}

.hero-banner-content {
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);

    @include media-breakpoint-up(md) {
        top: 40%;
    }

    img {
        display: block;
        height: 80px;
        width: 80px;
        margin: 1rem auto 1rem auto;

        @include media-breakpoint-up(lg) {
            display: inline;
            margin-right: 1.5rem;
            height: 150px;
            width: 150px;
        }
    }

    h1 {
        font-size: 2.75rem;
        line-height: 2.75rem;
        text-transform: uppercase;
        vertical-align: middle;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        @include media-breakpoint-up(md) {
            font-size: 3rem;
            line-height: 3rem;
            display: block;
        }

        @include media-breakpoint-up(lg) {
            font-size: 5rem;
            line-height: 5rem;
            display: inline;
        }
    }
}

.no-banner h1 {
    text-transform: uppercase;
    font-size: 4rem;

    @include media-breakpoint-down(md) {
        font-size: 3.5rem;
    }
}

.water-systems {
    .no-banner h1 {
        text-transform: unset !important;
        font-size: 2.8rem !important;
    }
}
.headwater {
    .no-banner h1 {
        text-transform: unset !important;
        font-size: 3.5rem !important;
    }
}
